import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-red-500">
      <div className="footer-links flex flex-wrap justify-center items-center text-white">
        <div className="flex flex-wrap justify-center items-center space-x-4 mx-auto py-2">
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-service">Terms of Service</Link>
        </div>

        <div className="copyright mr-4 py-2">
          {/* <p className="text-center text-red-300 pb-2">&copy; 2024 Noleyo. All Rights Reserved.</p> */}
          <p className="text-center text-xs text-red-300">
            &copy; by{" "}
            <a
              href="https://www.linkedin.com/in/%C3%B6mer-faz%C4%B1l-y%C3%BCr%C3%BCk-5386ab1a8/"
              target="_blank"
              rel="external nofollow noopener noreferrer"
            >
              Fazıl Yürük
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
