import React from "react";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Terms of Service & End User License Agreement (EULA)</h1>

      <p className="mb-4">Last updated: March 5, 2025</p>

      <p className="mb-4">
        Welcome to Noleyo! By accessing or using our app, you agree to comply with and be bound by these Terms of Service (the "Terms"). If
        you do not agree, please do not use Noleyo.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">1. Acceptance of Terms</h2>
      <p className="mb-4">
        By using Noleyo, you confirm that you are at least 13 years old (or the minimum age required in your country) and have the legal
        capacity to agree to these Terms.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">2. User Accounts</h2>
      <ul className="list-disc list-inside mb-4">
        <li>You must provide accurate information when creating an account.</li>
        <li>You are responsible for maintaining the security of your account.</li>
        <li>Noleyo is not responsible for unauthorized access due to your failure to secure your credentials.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">3. User-Generated Content</h2>
      <p className="mb-4">
        Noleyo allows users to create chatrooms, send messages, and interact with others. By using these features, you agree to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Not post illegal, harmful, or offensive content.</li>
        <li>Respect other users and avoid harassment, hate speech, or abusive behavior.</li>
        <li>Understand that Noleyo may remove or moderate content that violates our policies.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">4. Content Moderation & Reporting</h2>
      <p className="mb-4">
        Noleyo provides mechanisms to report inappropriate content and block users. Reports are reviewed within 24 hours, and we may take
        action, including removing content or banning users who violate our policies.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">5. License & Restrictions</h2>
      <ul className="list-disc list-inside mb-4">
        <li>Noleyo grants you a limited, non-exclusive, non-transferable license to use the app.</li>
        <li>You may not reverse-engineer, distribute, or use Noleyo for unlawful purposes.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">6. Privacy & Data Collection</h2>
      <p className="mb-4">
        Your use of Noleyo is also governed by our{" "}
        <Link to="/privacy-policy" className="text-blue-600 underline">
          Privacy Policy
        </Link>
        , which outlines how we collect, use, and protect your data.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">7. Termination</h2>
      <p className="mb-4">
        We reserve the right to terminate or suspend your access to Noleyo if you violate these Terms or engage in behavior that harms our
        community.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">8. Changes to These Terms</h2>
      <p className="mb-4">
        Noleyo may update these Terms from time to time. We will notify you of significant changes through the app or via email.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">9. Contact Information</h2>
      <p className="mb-4">
        If you have questions about these Terms, please contact us at{" "}
        <a href="mailto:support@noleyo.com" className="text-blue-600 underline">
          support@noleyo.com
        </a>
        .
      </p>

      <p className="mt-6 text-gray-500">By using Noleyo, you acknowledge that you have read, understood, and agreed to these Terms.</p>
    </div>
  );
};

export default TermsOfService;
