const Main = () => {
  return (
    <main className="flex-1 flex flex-col justify-center items-center p-6 text-center">
      <p className="text-zinc-700 text-2xl max-w-xl mb-6">
        Hey everyone, Noleyo is an application where you connect with your activity peers!
      </p>
      <p className="text-zinc-600 text-lg max-w-lg mb-8">
        Noleyo is now available on both the App Store and Play Store. Download now and start connecting! 🚀
      </p>
      <div className="flex gap-4">
        <a
          href="https://play.google.com/store/apps/details?id=com.noleyo.noleyoapp"
          target="_blank"
          rel="noopener noreferrer"
          className="px-6 py-3 bg-red-600 text-white text-lg rounded-lg shadow-md hover:bg-red-500 transition"
        >
          Get it on Play Store
        </a>
        <a
          href="https://apps.apple.com/us/app/noleyo/id6740176366"
          target="_blank"
          rel="noopener noreferrer"
          className="px-6 py-3 bg-black text-white text-lg rounded-lg shadow-md hover:bg-gray-800 transition"
        >
          Download on App Store
        </a>
      </div>
    </main>
  );
};

export default Main;
